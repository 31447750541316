import _ from "lodash";
import querystring from "qs";

interface TypeRouteStore {
  hotel?: string;
  setHotel: (hotel: string) => void;
  breadcrumbs: (ruleName: string, value?: any) => any;
  addRule: (ruleName: string, value: any) => void;
  toUrl: (ruleName: string, params?: object) => string;
}
interface TypeGetRouteStore {
  (): TypeRouteStore;
}
const handler: any = {
  variant: (_ruleName: string, params: object, _breadcrumbs: []) => {
    const productId = _.get(params, "id");
    const variantId = _.get(params, "variantId");
    return `/product/${productId}/variant/${variantId}`;
  },
  variants: (_ruleName: string, params: object, _breadcrumbs: []) => {
    const productId = _.get(params, "id");
    return `/product/${productId}/variants`;
  },
  createVariant: (_ruleName: string, params: object, _breadcrumbs: []) => {
    const productId = _.get(params, "id");
    return `/product/${productId}/variant/create`;
  },
};

const HOTELS = {
  "amanaki-thao-dien": true,
  "amanaki-boutique-saigon": true,
};
export const helper = {
  url: (ruleName: string, params?: any) => {
    if (_.has(handler, ruleName)) return handler[ruleName](ruleName, params);
    if (_.isEmpty(ruleName)) return "/";
    if (_.get(params, "id")) {
      return `/${_.join(_.compact([ruleName, _.get(params, "id")]), "/")}`;
    }
    if (_.get(params, "slug")) {
      return `/${_.join(_.compact([ruleName, _.get(params, "slug")]), "/")}`;
    }
    const query = querystring.stringify(params);
    if (!!query) {
      return `/${_.join(_.compact([ruleName]), "/")}?${query}`;
    }
    return `/${_.join(_.compact([ruleName]), "/")}`;
  },
  urlHotel: (ruleName: string, params?: any) => {
    const hotel = _.has(HOTELS, ruleName) ? "" : routeStore.hotel;
    if (_.has(handler, ruleName)) return handler[ruleName](ruleName, params);
    if (_.isEmpty(ruleName)) return "/";
    if (_.get(params, "id")) {
      return `/${_.join(
        _.compact([hotel, ruleName, _.get(params, "id")]),
        "/"
      )}`;
    }
    if (_.get(params, "slug")) {
      return `/${_.join(
        _.compact([hotel, ruleName, _.get(params, "slug")]),
        "/"
      )}`;
    }
    const query = querystring.stringify(params);
    if (!!query) {
      return `/${_.join(_.compact([hotel, ruleName]), "/")}?${query}`;
    }
    return `/${_.join(_.compact([hotel, ruleName]), "/")}`;
  },
};
const getRouteStore: TypeGetRouteStore = () => {
  const store: { [key: string]: any } = {};
  const routeStore = {
    hotel: "",
    setHotel: (value: string) => {
      routeStore.hotel = value;
    },
    addRule: (ruleName: string, config: object) => {
      store[ruleName] = config;
      // console.log(`add rule name:  ${ruleName}`);
    },
    toUrl: (ruleName: string, params?: object) => {
      const rule = _.get(store, ruleName);
      if (rule && _.isFunction(rule.url)) {
        return rule.url(params);
      }
      throw Error(`Rulename '${ruleName}' is not found`);
    },
    breadcrumbs: (ruleName: string, params?: object) => {
      const rule = _.get(store, ruleName);
      if (rule && _.isFunction(rule.breadcrumbs)) {
        return rule.breadcrumbs(params);
      }
      throw Error(`Breadcrumb '${ruleName}' is not found`);
    },
  };
  return routeStore;
};

export const routeStore = getRouteStore();

export default routeStore;
